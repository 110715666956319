import React from 'react';
import { Link } from 'gatsby';
import startCase from 'lodash/startCase';

import SEO from '../../components/seo';
import categories from '../../data/categories';
import TemplateLayout from '../../templates/layout';
import Breadcrumb from '../../components/breadcrumb';

const IndexPage = () => (
  <TemplateLayout>
    <SEO title="Datayears | Categories" />
    <Breadcrumb
        items={[
            { name: 'Home', linkTo: '/' },
            { name: 'Categories' },
        ]}
    />
    {categories.map(category => (
        <div key={category}>
            <Link to={`/${category}`}>
                {startCase(category)}
            </Link>
        </div>
    ))}
  </TemplateLayout>
);

export default IndexPage;
